<template>
  <div>
    <v-row no-glutters class="body-rows">
      <v-col cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/verification" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Step 3 of 6
        </p>
        <p class="headline font-weight-black mb-2">Personal details</p>
        <p class="subtitle-1 font-weight-regular txt-666">We need to know a little about you and your Investment/trading experience</p>

        <v-alert v-if="rejectdata.app_status == 'rejected' ? rejectdata.stage_wise_approval.personal == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
          <span class="subtitle-2 font-weight-regular"
            >Your Personal have been rejected due <b>{{ rejectdata.personal_reject_reason }}</b></span
          >
        </v-alert>

        <div v-if="!dataloader" class="pt-md-3">
          <p class="font-weight-medium fs-17 mb-0">Your mother name</p>
          <!-- :rules="[v => !!v || 'Mother name is required', v => (v || '').indexOf('  ') < 0 || 'No spaces are allowed',(v) =>/^[A-Z\-]*$/.test(v) || 'Names must be in letters.']" -->
          <v-text-field
            @input="validateMotherName()"
            v-model="motherName"
            required
            type="text"
            v-on:keypress="isLetter($event)"
            :rules="[(v) => !!v || 'Mother name is required', (v) => /^[A-Za-z\s]+$/.test(v) || 'Only letters are allowed']"
            class="txt-field mt-0 mb-4"
            oninput="this.value = this.value.toUpperCase().replace('  ', '')"
          >
            <template #prepend-inner>
              <div class="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M5 9C5.55228 9 6 8.55228 6 8C6 7.44772 5.55228 7 5 7C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9Z" fill="#212121" />
                  <path d="M5 10C5.53043 10 6.03914 10.2107 6.41421 10.5858C6.78929 10.9609 7 11.4696 7 12H3C3 11.4696 3.21071 10.9609 3.58579 10.5858C3.96086 10.2107 4.46957 10 5 10Z" fill="#212121" />
                  <path d="M9.5 8.5H12.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9.5 11.5H12.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M4.5 3.5H1.5C1.23478 3.5 0.98043 3.60536 0.792893 3.79289C0.605357 3.98043 0.5 4.23478 0.5 4.5V13.5C0.5 13.7652 0.605357 14.0196 0.792893 14.2071C0.98043 14.3946 1.23478 14.5 1.5 14.5H14.5C14.7652 14.5 15.0196 14.3946 15.2071 14.2071C15.3946 14.0196 15.5 13.7652 15.5 13.5V4.5C15.5 4.23478 15.3946 3.98043 15.2071 3.79289C15.0196 3.60536 14.7652 3.5 14.5 3.5H11.5"
                    stroke="#212121"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M8 0.5C8.39782 0.5 8.77936 0.658035 9.06066 0.93934C9.34196 1.22064 9.5 1.60218 9.5 2V5.5H6.5V2C6.5 1.60218 6.65804 1.22064 6.93934 0.93934C7.22064 0.658035 7.60218 0.5 8 0.5Z" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </template>
          </v-text-field>

          <p class="font-weight-medium fs-17 mb-0">Your birth place</p>
          <v-text-field
            v-model="birthName"
            required
            type="text"
            v-on:keypress="isLetter($event)"
            ref="birthref"
            :rules="[(v) => !!v || 'birth place is required', (v) => /^[A-Za-z\s]+$/.test(v) || 'Only letters are allowed']"
            class="txt-field mt-0"
            oninput="this.value = this.value.toUpperCase().replace('  ', '')"
          >
          </v-text-field>

          <p class="font-weight-medium fs-17 mb-0 mt-4">Select your marital status</p>
          <v-chip-group column>
            <v-chip @click="maritialStatus = tag" :color="maritialStatus == tag ? '#000' : '#666666'" :text-color="maritialStatus == tag ? '#fff' : '#666666'" v-for="tag in marital" :outlined="maritialStatus != tag" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>

          <p class="font-weight-medium fs-17 mb-0 mt-4 mt-md-6">Your annual Income</p>
          <v-chip-group column>
            <v-chip @click="annualIncome = tag" :color="annualIncome == tag ? '#000' : '#666666'" :text-color="annualIncome == tag ? '#fff' : '#666666'" v-for="tag in anuincome" :outlined="annualIncome != tag" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>

          <p class="font-weight-medium fs-17 mb-0 mt-4 mt-md-6">
            Your occupation <v-chip v-if="occupation == 'Others'" small class="ml-2" color="#000" text-color="#fff"> Other <v-icon @click="occupation = ''" color="#fff" size="16" class="ml-2">mdi-close</v-icon> </v-chip>
          </p>
          <v-chip-group v-if="occupation != 'Others'" column>
            <v-chip @click="occupation = tag" :color="occupation == tag ? '#000' : '#666666'" :text-color="occupation == tag ? '#fff' : '#666666'" v-for="tag in occupations" :outlined="occupation != tag" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>
          <v-text-field v-else placeholder="Enter your occupation" v-model="occupationInput" v-on:keypress="isLetter($event)" :readonly="cardloader" class="txt-field mb-8" hide-details oninput="this.value = this.value.replace('  ', '')"> </v-text-field>

          <v-divider style="border: dashed #f1f6f5; border-width: thin 0 thin 0 !important" class="mt-3"></v-divider>

          <p class="font-weight-medium fs-17 mb-0 mt-4">Your trading experience</p>
          <v-chip-group column class="mb-7">
            <v-chip @click="tradingExperience = tag" :color="tradingExperience == tag ? '#000' : '#666666'" :text-color="tradingExperience == tag ? '#fff' : '#666666'" v-for="tag in trading" :outlined="tradingExperience != tag" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>

          <p class="font-weight-medium fs-17 mb-0 mt-4 mt-md-6">
            Are you dealing through other stock Broker ?
            <v-chip v-if="broker == 'Yes'" small class="ml-2" color="#000" text-color="#fff"> Yes <v-icon @click="(broker = ''), (brokerInput = ''), (brokerid = '')" color="#fff" size="16" class="ml-2">mdi-close</v-icon> </v-chip>
          </p>
          <v-chip-group v-if="broker != 'Yes'" column>
            <v-chip @click="broker = tag" :color="broker == tag ? '#000' : '#666666'" :text-color="broker == tag ? '#fff' : '#666666'" v-for="tag in brokers" :outlined="broker != tag" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>
          <v-row no-glutters v-else class="">
            <v-col cols="12" md="6">
              <v-text-field placeholder="Enter your other broker name" v-model="brokerInput" v-on:keypress="isLetter($event)" :readonly="cardloader" class="txt-field mb-2" hide-details oninput="this.value = this.value.replace('  ', '')"> </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field placeholder="Enter your other broker Client ID" v-model="brokerid" :readonly="cardloader" oninput="this.value = this.value.toUpperCase().replace(' ', '')" class="txt-field mb-2" hide-details> </v-text-field>
            </v-col>
          </v-row>

          <p class="font-weight-medium fs-17 mb-0 mt-4 mt-md-6">
            Are you introduced by anyone ? <v-chip v-if="intro == 'Yes'" small class="ml-2" color="#000" text-color="#fff"> Yes <v-icon @click="(intro = ''), (introInput = ''), (introid = '')" color="#fff" size="16" class="ml-2">mdi-close</v-icon> </v-chip>
          </p>
          <v-chip-group v-if="intro != 'Yes'" column>
            <v-chip @click="intro = tag" :color="intro == tag ? '#000' : '#666666'" :text-color="intro == tag ? '#fff' : '#666666'" v-for="tag in introd" :outlined="intro != tag" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>
          <v-row no-glutters v-else>
            <v-col cols="12" md="6">
              <v-text-field placeholder="Enter your introducer name" v-model="introInput" v-on:keypress="isLetter($event)" :readonly="cardloader" class="txt-field mb-2" hide-details oninput="this.value = this.value.replace('  ', '')"> </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field placeholder="Enter your introducer Client ID" v-model="introid" :readonly="cardloader" oninput="this.value = this.value.toUpperCase().replace(' ', '')" class="txt-field mb-2" hide-details> </v-text-field>
            </v-col>
          </v-row>

          <p class="font-weight-medium fs-17 mb-0 mt-4 mt-md-6">Are you politically exposed or related person ?</p>
          <v-chip-group column class="mb-16 mb-md-0">
            <v-chip @click="politically = tag" :color="politically == tag ? '#000' : '#666666'" :text-color="politically == tag ? '#fff' : '#666666'" v-for="tag in politicallys" :outlined="politically != tag" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>

          <div class="d-none d-md-flex">
            <v-btn
              v-if="
                tradingExperience &&
                politically &&
                occupation &&
                maritialStatus &&
                annualIncome &&
                maritialStatus &&
                motherName &&
                motherName.trim() &&
                birthName &&
                birthName.trim() &&
                (occupation == 'Others' ? (occupationInput.length > 0 ? true : false) : true) &&
                broker &&
                (broker == 'Yes' ? (brokerInput.length > 0 && brokerid.length > 0 ? true : false) : true) &&
                intro &&
                (intro == 'Yes' ? (introInput.length > 0 && introid.length > 0 ? true : false) : true)
              "
              @click="personalupdation()"
              height="48px"
              color="#000"
              :loading="cardloader"
              class="white--text text-none rounded-pill elevation-0 mt-6"
              ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
            >
            <v-btn v-else height="48px" disabled color="#000" :loading="cardloader" class="white--text text-none rounded-pill elevation-0 mt-6"><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
          </div>
        </div>
        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>

        <v-footer padless style="position: absolute; bottom: 40px; left: 0; width: 100%" color="#fff" class="px-3 pt-3 pb-1 d-md-none">
          <v-btn
            v-if="
              tradingExperience &&
              politically &&
              occupation &&
              maritialStatus &&
              annualIncome &&
              maritialStatus &&
              motherName &&
              motherName.trim() &&
              birthName &&
              birthName.trim() &&
              (occupation == 'Others' ? (occupationInput.length > 0 ? true : false) : true) &&
              broker &&
              (broker == 'Yes' ? (brokerInput.length > 0 && brokerid.length > 0 ? true : false) : true) &&
              intro &&
              (intro == 'Yes' ? (introInput.length > 0 && introid.length > 0 ? true : false) : true)
            "
            @click="personalupdation()"
            height="48px"
            color="#000"
            :loading="cardloader"
            block
            class="white--text text-none rounded-pill elevation-0"
            ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
          >
          <v-btn v-else height="48px" disabled color="#000" :loading="cardloader" block class="white--text text-none rounded-pill elevation-0"><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
        </v-footer>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/per.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";
export default {
  data: () => ({
    name: "",
    gender: ["Male", "Female", "Others"],
    genis: "",
    marital: ["Single", "Married"],
    maritialStatus: "",
    trading: ["Nil", "Less than 1 yr", "1 - 2 yrs", "2 - 3 yrs", "3 - 4 yrs", "More than 5 yrs"],
    tradingExperience: "",
    anuincome: ["Below 1L", "1L - 5L", "5L - 10L", "10L - 25L", "Above 25L"],
    annualIncome: "",
    occupations: ["Private Sector", "Public Sector", "Government Service", "Business", "Housewife", "Student", "Retired", "Agriculture", "Others"],
    occupationInput: "",
    politicallys: ["Yes", "No", "Related to"],
    // politicallys: [
    //     "I am not",
    //     "I am",
    // ],
    politically: "",
    brokers: ["Yes", "No"],
    brokerInput: "",
    broker: "",
    brokerid: "",

    introd: ["Yes", "No"],
    introInput: "",
    intro: "",
    introid: "",

    motherName: "",
    birthName: "",
    occupation: "",
    pds: 0,
    cardloader: false,
    dataloader: null,
    pageis: null,
    rejectdata: [],
  }),
  created() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        var stage = localStorage.getItem("stageR");
        let p = response.data.data_type;
        if (
          response.data.data_type &&
          (response.data.stage_wise_approval.personal != "yes" || stage == "birth place missing") &&
          (p.stage == "personal" || p.stage == "bank" || p.stage == "bankproof" || p.stage == "segment" || p.stage == "declartaion" || p.stage == "signature" || p.stage == "nominee" || p.stage == "selfie" || p.stage == "submit")
        ) {
          axiosThis.$emit("clicked", 3, response.data);
          response.data.app_status == "rejected" && response.data.stage_wise_approval.personal == "no" ? (axiosThis.rejectdata = response.data) : (axiosThis.rejectdata = []);
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },
  methods: {
    validateMotherName() {
      this.motherName = this.motherName.replace(/[0-9]/g, "");
      this.motherName = this.motherName.toUpperCase().replace(/\s{2,}/g, " ");
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char) || char == " ") return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    personalupdation() {
      // this.$v.$touch();
      if (atob(localStorage.getItem("hash")) && this.birthName && this.motherName && this.tradingExperience != null && this.occupation != null && this.maritialStatus != null && this.annualIncome) {
        this.cardloader = true;
        // this.$v.$reset();
        var axiosThis = this;
        var json_data = {
          mobile: atob(localStorage.getItem("hash")),
          // segment: segment,
          //  spousename: axiosThis. spousename,
          // fathername: father_name,
          maritalstatus: axiosThis.maritialStatus == "Single" ? "single" : "married",
          annual_income: axiosThis.annualIncome == "Below 1L" ? "below 1 lac" : axiosThis.annualIncome == "1L - 5L" ? "1-5 lac" : axiosThis.annualIncome == "5L - 10L" ? "5-10 lac" : axiosThis.annualIncome == "10L - 25L" ? "10-25 lac" : axiosThis.annualIncome == "Above 25L" ? "25-1Cr" : "",
          motherName: axiosThis.motherName.trim(),
          birthName: axiosThis.birthName.trim(),

          // Education: axiosThis.qualification,
          TradingExperience:
            axiosThis.tradingExperience == "Nil"
              ? "0"
              : axiosThis.tradingExperience == "Less than 1 yr"
              ? "1"
              : axiosThis.tradingExperience == "1 - 2 yrs"
              ? "2"
              : axiosThis.tradingExperience == "2 - 3 yrs"
              ? "3"
              : axiosThis.tradingExperience == "3 - 4 yrs"
              ? "4"
              : axiosThis.tradingExperience == "More than 5 yrs"
              ? "5"
              : "",
          Occupation: axiosThis.occupation == "Others" ? axiosThis.occupationInput : axiosThis.occupation,
          // nominee_check: axiosThis.nominee,
          politically_exposed: axiosThis.politically == "Yes" ? "I am" : axiosThis.politically == "No" ? "I am not" : axiosThis.politically == "Related to" ? "Related to" : "I am not",
          other_broker: axiosThis.broker == "Yes" ? `${axiosThis.brokerid}|${axiosThis.brokerInput}` : axiosThis.broker,
          intro: axiosThis.intro == "Yes" ? `${axiosThis.introid}|${axiosThis.introInput}` : axiosThis.intro,
        };
        var config = {
          method: "post",
          url: api.api_url + "/signupGetUserPersonalDetails",
          headers: {"Content-Type": "application/json"},
          data: JSON.stringify(json_data),
        };
        axios(config)
          .then(function (response) {
            if (response.data) {
              var stage = localStorage.getItem("stageR");
              if (stage == "birth place missing") {
                localStorage.removeItem("stageR");
                axiosThis.$emit("clicked", "submit");
              } else if (axiosThis.rejectdata.app_status == "rejected" && axiosThis.rejectdata.stage_wise_approval.personal == "no") {
                axiosThis.$emit("steged", "personal");
              } else {
                axiosThis.$emit("clicked", "bank");
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.$emit("snack", 0, error);
          });
      }
    },
  },
  mounted() {
    this.dataloader = true;
    var axiosThis = this;
    var json_data = {
      mobile: atob(localStorage.getItem("hash")),
    };
    var config = {
      method: "post",
      url: api.api_url + "/signupGetUserPersonalDetails",
      headers: {"Content-Type": "application/json"},
      data: JSON.stringify(json_data),
    };
    axios(config)
      .then(function (response) {
        var data = response.data;
        if (data && "motherName" in data) {
          axiosThis.motherName = data["motherName"];
          axiosThis.birthName = data["birthName"];
          let index = axiosThis.occupations.findIndex((x) => x == data["occupation"]);
          if (index >= 0) {
            axiosThis.occupation = data["occupation"];
            axiosThis.occupationInput = "";
          }
          axiosThis.maritialStatus = ["single", "married"].includes(data["marital_status"]) ? data["marital_status"].charAt(0).toUpperCase() + data["marital_status"].slice(1) : "";
          // axiosThis.politicalExposed = data['political_exposed'];
          axiosThis.annualIncome = data["annual_income"] == "below 1 lac" ? "Below 1L" : data["annual_income"] == "1-5 lac" ? "1L - 5L" : data["annual_income"] == "5-10 lac" ? "5L - 10L" : data["annual_income"] == "10-25 lac" ? "10L - 25L" : data["annual_income"] == "25-1Cr" ? "Above 25L" : "";
          // axiosThis.nominee = data['nominee_check'];
          axiosThis.tradingExperience =
            data["tradeExpYear"] == "0" ? "Nil" : data["tradeExpYear"] == "1" ? "Less than 1 yr" : data["tradeExpYear"] == "2" ? "1 - 2 yrs" : data["tradeExpYear"] == "3" ? "2 - 3 yrs" : data["tradeExpYear"] == "4" ? "3 - 4 yrs" : data["tradeExpYear"] == "5" ? "More than 5 yrs" : "";
          axiosThis.name = data["name"];
          axiosThis.genis = data["gender"];
          axiosThis.politically = data["political_exposed"] == "I am" ? "Yes" : data["political_exposed"] == "I am not" ? "No" : data["political_exposed"] == "Related to" ? "Related to" : "";
          if (data["other_broker"]) {
            axiosThis.broker = data["other_broker"] == "No" ? "No" : data["other_broker"] ? "Yes" : "";
            axiosThis.brokerInput = data["other_broker"] != "No" ? data["other_broker"].split("|")[1] : "";
            axiosThis.brokerid = data["other_broker"] != "No" ? data["other_broker"].split("|")[0] : "";
          }
          if (data["intro"]) {
            axiosThis.intro = data["intro"] == "No" ? "No" : data["intro"] ? "Yes" : "";
            axiosThis.introInput = data["intro"] != "No" ? data["intro"].split("|")[1] : "";
            axiosThis.introid = data["intro"] != "No" ? data["intro"].split("|")[0] : "";
          }
          var stage = localStorage.getItem("stageR");
          if (stage == "birth place missing") {
            setTimeout(() => axiosThis.$refs.birthref.$refs.input.focus(), 100);
            axiosThis.birthName = null;
          }
          axiosThis.pageis = true;
        } else {
          axiosThis.pageis = false;
        }
        axiosThis.cardloader = false;
        axiosThis.dataloader = false;
      })
      .catch(function (error) {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },
};
</script>
