<template>
  <div>
    <!-- <v-container class="overflow-auto py-16" fill-height width="100%"> -->
    <!-- <v-card width="100%" class="text-center elevation-0 py-16 my-16">
      <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
    </v-card> -->
    <!-- {{ acctoken }} {{ loaders }} -->
    <!-- <v-btn class="mb-4 mx-4 text-none" :loading="loaders" @click="startOnboarding('workflow_jDFDeDc')"> Start onb </v-btn> -->
    <!-- <v-btn class="mb-4 mx-4 text-none" :loading="loaders" @click="startOnboarding('zebu_wet_signature')"> Start wet signature </v-btn> -->
    <!-- <v-btn class="mb-4 mx-4 text-none" :loading="loaders" @click="startOnboarding('zebu_flow')"> Start selfie </v-btn> -->
    <!-- </v-container> -->
  </div>
</template>

<script src="https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.9.1/src/sdk.min.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.9.1/src/sdk.min.js";
document.body.appendChild(script);

import api from "@/api";
import axios from "axios";
export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      snackcolor: "#2A2A2A",
      snacktxt: null,
      snackbar: false,

      loaders: false,
      acctoken: null,

      uid: null,
    };
  },
  methods: {
    startOnboarding(type) {
      // console.warn(this.acctoken.token, type, this.uid);
      let url = this.$route.name;
      if (url == "signature" || url == "selfie") {
        const hyperKycConfig = new window.HyperKycConfig(this.acctoken.token, type, this.uid);
        window.HyperKYCModule.launch(hyperKycConfig, this.setHandler);
      } else {
        console.warn("not entry in hyper");
      }
    },

    setHandler(HyperKycResult) {
      switch (HyperKycResult.status) {
        // ----Incomplete workflow-----

        case "user_cancelled":
          this.setResponesHv(HyperKycResult, 2, "Cancelled by yourself");
          break;
        case "error":
          this.setResponesHv(HyperKycResult, 0, "server error");
          break;

        // ----Complete workflow-----

        case "auto_approved":
          this.setResponesHv(HyperKycResult, 1, "Successfully completed");
          break;
        case "auto_declined":
          this.setResponesHv(HyperKycResult, 0, "Data missmatch");
          break;
        case "needs_review":
          this.setResponesHv(HyperKycResult, 1, "Manually reviewed");
          break;
      }
    },
    setResponesHv(raw, clr, msg) {
      // console.log(raw, clr, msg);
      if (raw) {
        this.$emit("setHVstat", raw, clr, msg, this.uid);
      } else {
        alert("HV Server not reachable, please try again later. \n  For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com");
      }

      // else if (clr == 1) {
      //   // && clr == 3 this.$emit("setHVstat", raw, clr, msg, this.uid);

      //   let data = JSON.stringify({
      //     transactionId: this.uid,
      //     sendDebugInfo: "no",
      //     sendReviewDetails: "yes",
      //   });

      //   let config = {
      //     method: "post",
      //     url: `${api.hyv}/v1/output`,
      //     headers: {
      //       appId: this.acctoken.appId,
      //       appKey: this.acctoken.appKey,
      //       "Content-Type": "application/json",
      //     },
      //     data: data,
      //   };

      //   axios
      //     .request(config)
      //     .then((res) => {
      //       if (res.data) {
      //         console.log(res.data);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // }
    },
    async getLoginSess() {
      let config = {
        method: "post",
        url: `${api.api_url}/HypervergeAuth`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      let axs = this;
      await axios
        .request(config)
        .then((res) => {
          if (res.data && res.data.appId && res.data.token) {
            axs.acctoken = res.data;
            var url = new URL(window.location.href);
            const mode = url.searchParams.get("mode");
            if (mode) {
              axs.mode = mode;
            }
            axs.uid = atob(localStorage.getItem("hash")) + `_${axs.mode == "zebu_flow" ? "IPV" : "Sig"}${axs.generateIdFromDate()}`;
            setTimeout(() => {
              axs.startOnboarding(axs.mode);
            }, 100);
          } else {
            axs.acctoken = null;
          }
          axs.loaders = false;
        })
        .catch((error) => {
          console.log(error);
          axs.acctoken = null;
          axs.loaders = false;
        });
    },

    generateIdFromDate() {
      // Extract components from the Date object
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const year = String(date.getFullYear()).slice(2); // Get last two digits of the year
      const hour = String(date.getHours()).padStart(2, "0");
      const minute = String(date.getMinutes()).padStart(2, "0");
      // const second = String(date.getSeconds()).padStart(2, "0");

      // Construct the ID
      const id = `${day}${month}${year}${hour}${minute}`;
      return id;
    },
  },

  async mounted() {
    // const script = document.createElement("script");
    // script.src = "https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.9.1/src/sdk.min.js";
    // script.async = true;
    // await document.head.appendChild(script);
    // setTimeout(() => {
    this.getLoginSess();
    // }, 0);
  },
};
</script>
