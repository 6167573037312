<template>
  <div>
    <v-app-bar width="100%" permanent dense class="cust-appbar px-0 px-md-2">
      <img src="https://zebu.mynt.in/static/logo/mainlogo.svg" style="max-width: 80px" alt="zebulogo" />
      <v-spacer></v-spacer>
      <v-menu offset-y class="rounded-xl">
        <template v-slot:activator="{on, attrs}">
          <div v-bind="attrs" v-on="on" class="d-inline-flex">
            <v-icon color="subtext" size="20">mdi-headphones</v-icon>
            <p class="subtext--text font-weight-medium mb-0 ml-2">Need Help ?</p>
          </div>
        </template>
        <v-card class="px-6 py-4 elevation-0 rounded-xl mr-4">
          <p class="font-weight-medium title mb-1">Can we help you?</p>

          <a href="tel:+91 93 8010 8010" rel="noopener noreferrer" target="_blank" class="blue-grey--text">
            <p class="mb-0 font-weight-bold mb-0"><v-icon color="blue-grey" size="18">mdi-phone</v-icon><span class="ml-2">(+91) 93 8010 8010</span></p></a
          >

          <a href="mailto:assist@zebuetrade.com" rel="noopener noreferrer" target="_blank" class="blue-grey--text">
            <p class="mb-0 blue-grey--text font-weight-bold mb-3"><v-icon color="blue-grey" size="18">mdi-send</v-icon><span class="ml-2">assist@zebuetrade.com</span></p></a
          >
          <p class="font-weight-medium mb-0">
            Investor grievance: <br />
            <a href="mailto:grievance@zebuetrade.com" rel="noopener noreferrer" target="_blank" class="blue-grey--text"> <span class="font-weight-bold blue-grey--text">grievance@zebuetrade.com</span></a>
          </p>
        </v-card>
      </v-menu>
    </v-app-bar>
    <div>
      <div v-if="onLine">
        <v-snackbar class="snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar" timeout="4000" :value="true" :color="snackcolor" absolute text-color="white">
          <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
          <span class="font-weight-medium" v-html="snacktxt"></span>
          <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <v-container class="body-card overflow-hidden pa-0 pt-10" fill-height :class="e1 > 0 ? 'pt-md-6' : 'pt-md-13'">
          <div class="body-part px-0 px-md-0">
            <v-stepper v-if="elstage" v-model="e1" class="elevation-0 d-none d-md-block">
              <v-stepper-header color="#888">
                <v-stepper-step class="cur-poi" @click="rejectdata.change ? moveStepper(e1 >= 1 ? 1 : 0) : ''" :rules="[() => rejectdata.sato]" :complete="e1 > 1" step="1" color="#43A833">
                  <v-tooltip bottom color="#000">
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on" :class="liveon == 1 ? 'font-weight-black black--text' : ''" class="txt-666 subtitle-2 font-weight-medium">PAN</span>
                    </template>
                    <span>PAN Verification</span>
                  </v-tooltip>
                </v-stepper-step>
                <v-divider :style="`border-color: ${e1 > 1 ? '#50BE4D' : '#D9D9D9'};`"></v-divider>
                <v-stepper-step class="cur-poi" @click="rejectdata.change ? moveStepper(e1 >= 2 ? 2 : 0) : ''" :rules="[() => rejectdata.satt]" :complete="e1 > 2" step="2" color="#43A833">
                  <v-tooltip bottom color="#000">
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on" :class="liveon == 2 ? 'font-weight-black black--text' : ''" class="txt-666 subtitle-2 font-weight-medium">{{ verifytype }}</span>
                    </template>
                    <span>{{ verifytype }} Verification</span>
                  </v-tooltip>
                </v-stepper-step>
                <v-divider :style="`border-color: ${e1 > 2 ? '#50BE4D' : '#D9D9D9'};`"></v-divider>
                <v-stepper-step class="cur-poi" @click="rejectdata.change ? moveStepper(e1 >= 3 ? 3 : 0) : ''" :rules="[() => rejectdata.satr]" :complete="e1 > 3" step="3" color="#43A833">
                  <v-tooltip bottom color="#000">
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on" :class="liveon == 3 ? 'font-weight-black black--text' : ''" class="txt-666 subtitle-2 font-weight-medium">Personal</span>
                    </template>
                    <span>Personal Verification</span>
                  </v-tooltip>
                </v-stepper-step>
                <v-divider :style="`border-color: ${e1 > 3 ? '#50BE4D' : '#D9D9D9'};`"></v-divider>
                <v-stepper-step class="cur-poi" @click="rejectdata.change ? moveStepper(e1 >= 4 ? 4 : 0) : ''" :rules="[() => rejectdata.satf]" :complete="e1 > 4" step="4" color="#43A833">
                  <v-tooltip bottom color="#000">
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on" :class="liveon == 4 ? 'font-weight-black black--text' : ''" class="txt-666 subtitle-2 font-weight-medium">Bank</span>
                    </template>
                    <span>Bank Verification</span>
                  </v-tooltip>
                </v-stepper-step>
                <v-divider :style="`border-color: ${e1 > 4 ? '#50BE4D' : '#D9D9D9'};`"></v-divider>
                <v-stepper-step class="cur-poi" @click="rejectdata.change ? moveStepper(e1 >= 5 ? 5 : 0) : ''" :rules="[() => rejectdata.satv]" :complete="e1 > 5" step="5" color="#43A833">
                  <v-tooltip bottom color="#000">
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on" :class="liveon == 5 ? 'font-weight-black black--text' : ''" class="txt-666 subtitle-2 font-weight-medium">IPV</span>
                    </template>
                    <span>In-Person Verification</span>
                  </v-tooltip>
                </v-stepper-step>
                <v-divider :style="`border-color: ${e1 > 5 ? '#50BE4D' : '#D9D9D9'};`"></v-divider>
                <v-stepper-step class="cur-poi" @click="rejectdata.change ? moveStepper(e1 >= 6 ? 6 : 0) : ''" :complete="e1 > 6" step="6" color="#43A833">
                  <v-tooltip bottom color="#000">
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on" :class="liveon == 6 ? 'font-weight-black black--text' : ''" class="txt-666 subtitle-2 font-weight-medium">Submit</span>
                    </template>
                    <span>Complete Verification</span>
                  </v-tooltip>
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>

            <v-card width="100%" class="mx-0 ma-lg-auto rounded-xl pa-4 px-md-7 py-md-4 px-lg-8 py-lg-5 overflow-hidden" :class="elstage ? 'body-temps' : 'body-temp'">
              <router-view @clicked="onClickChild" @steged="stageUpdate" @changed="setStagechange" @snack="snackAlert" />
            </v-card>
          </div>
        </v-container>
      </div>
      <div v-else-if="!onLine">
        <v-container class="body-card overflow-hidden pa-0 pt-10" fill-height>
          <div class="ma-auto text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
              <path
                d="M81.6636 53.3334C88.9613 55.6071 95.7992 59.3019 101.76 64.2667M120.426 45.3332C104.84 31.5943 84.7766 24.014 63.9995 24.014C60.7728 24.014 57.5633 24.1968 54.3851 24.5572M45.4921 83.2535C50.9065 79.4068 57.3837 77.3402 64.0254 77.3402C70.6672 77.3402 77.1444 79.4068 82.5588 83.2535M63.9999 104H64.0533M6.3645 46.4041C13.4771 39.8864 21.698 34.5587 30.712 30.7363M25.2369 65.2958C32.6897 58.7306 41.8224 54.0275 51.9074 51.9143M83.7243 84.1339C78.2891 79.8734 71.4408 77.3333 63.9993 77.3333C56.4452 77.3333 49.5024 79.9509 44.0286 84.3284M15.9999 16L112 112"
                stroke="black"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p style="color: #2a2a2a" class="mb-0 font-weight-bold fs-16">
              No Internet connection was found. check <br />
              your connection or try again.
            </p>

            <v-btn @click="setLoad()" class="mt-4 text-none px-8 white--text font-weight-bold rounded-xl" color="#2a2a2a"> Try again </v-btn>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";

export default {
  data: () => ({
    snackcolor: "#2A2A2A",
    snacktxt: null,
    snackbar: false,

    e1: 0,
    elstage: null,
    onLine: null,
    srcref: null,
    rejectdata: [],

    liveon: 0,

    verifytype: "Aadhaar",
  }),

  created() {
    document.title = "E-Kyc - Zebu";
    this.myOnline();
    this.rejectdata["change"] = true;

    let p = window.location;
    console.log(p);

    if (p.pathname == "/digilocker") {
      this.$router.push(`/verification${p.search}`);
    }
  },
  methods: {
    setStagechange(change) {
      this.rejectdata = [];
      if (change) {
        let r = change;
        if (r == 1) {
          this.rejectdata["sato"] = false;
        } else {
          this.rejectdata["sato"] = true;
        }
        if (r == 2) {
          this.rejectdata["satt"] = false;
        } else {
          this.rejectdata["satt"] = true;
        }
        if (r == 3) {
          this.rejectdata["satr"] = false;
        } else {
          this.rejectdata["satr"] = true;
        }
        if (r == 4) {
          this.rejectdata["satf"] = false;
        } else {
          this.rejectdata["satf"] = true;
        }
        if (r == 5) {
          this.rejectdata["satv"] = false;
        } else {
          this.rejectdata["satv"] = true;
        }
        this.rejectdata["change"] = false;
      } else {
        this.rejectdata["change"] = true;

        this.rejectdata["sato"] = true;
        this.rejectdata["satt"] = true;
        this.rejectdata["satr"] = true;
        this.rejectdata["satf"] = true;
        this.rejectdata["satv"] = true;
      }
    },
    moveStepper(s) {
      let p = window.location.pathname;
      if (s == 1 && p != "/pan") {
        this.$router.push("/pan");
      } else if (s == 2 && p != "/verification") {
        this.$router.push("/verification");
      } else if (s == 3 && p != "/personal") {
        this.$router.push("/personal");
      } else if (s == 4 && p != "/bank") {
        this.$router.push("/bank");
      } else if (s == 5 && p != "/declartaion") {
        this.$router.push("/declartaion");
      } else if (s == 6 && p != "/submit") {
        this.$router.push("/submit");
      }
    },
    setLoad() {
      location.reload();
    },
    myOnline() {
      if (navigator.onLine) {
        this.onLine = true;
      } else {
        this.onLine = false;
      }
    },
    getStatment() {
      let data = JSON.stringify({
        srcref: this.srcref,
      });

      let config = {
        method: "post",
        url: "https://rekycbe.mynt.in/cams/getStatementFileLocation",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data && response.data.data != "no data found") {
            let data = JSON.stringify({
              mobile: atob(localStorage.getItem("hash")),
              srcref: this.srcref,
            });

            let config = {
              method: "post",
              url: api.api_url + "/bankstatement",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            axios
              .request(config)
              .then(() => {})
              .catch((error) => {
                console.log(error);
                axiosThis.snackAlert(0, error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.snackAlert(0, error);
        });
    },
    onClickChild(value, raw) {
      this.myOnline();
      if (value > 0 && value < 8) {
        this.liveon = value;
        if (raw.msg == "pls check number") {
          localStorage.clear();
          sessionStorage.clear();
        } else if (raw.data_type) {
          this.verifytype = raw.data_type && raw.data_type.kra == "true" ? "KRA" : "Aadhaar";
          localStorage.setItem("cGFnZWlz", raw.data_type.stage);
          if (raw.app_status == "rejected") {
            let r = raw.stage_wise_approval;
            if (r.pan == "no") {
              this.rejectdata["sato"] = false;
            } else {
              this.rejectdata["sato"] = true;
            }
            if (r.digilocker == "no" || r.panproof == "no") {
              this.rejectdata["satt"] = false;
            } else {
              this.rejectdata["satt"] = true;
            }
            if (r.personal == "no") {
              this.rejectdata["satr"] = false;
            } else {
              this.rejectdata["satr"] = true;
            }
            if (r.bank == "no" || r.bankproof == "no" || r.segment == "no") {
              this.rejectdata["satf"] = false;
            } else {
              this.rejectdata["satf"] = true;
            }
            if (r.signature == "no" || r.nominee == "no" || r.selfie == "no") {
              this.rejectdata["satv"] = false;
            } else {
              this.rejectdata["satv"] = true;
            }
          } else {
            this.rejectdata["sato"] = value == 1 && this.rejectdata["sato"] == false ? false : true;
            this.rejectdata["satt"] = true;
            this.rejectdata["satr"] = true;
            this.rejectdata["satf"] = true;
            this.rejectdata["satv"] = true;
          }
          this.srcref = raw.data_type.srcref;
          let page = raw.data_type.stage;
          if (page == "mobile" || page == "email") {
            this.e1 = 0;
            this.elstage = false;
          } else if (page == "pan" || page == "onboard") {
            this.e1 = 1;
            this.elstage = true;
          } else if (page == "verification" || page == "digilocker" || page == "panproof") {
            this.e1 = 2;
            this.elstage = true;
          } else if (page == "personal") {
            this.e1 = 3;
            this.elstage = true;
          } else if (page == "bank" || page == "bankproof" || page == "segment") {
            this.e1 = 4;
            this.elstage = true;
          } else if (page == "declartaion" || page == "signature" || page == "nominee" || page == "selfie") {
            this.e1 = 5;
            this.elstage = true;
          } else if (page == "submit") {
            this.e1 = 6;
            this.elstage = true;
          } else if (page == "complete") {
            this.e1 = 7;
            this.elstage = false;
          } else {
            this.e1 = 0;
            this.elstage = false;
            localStorage.clear();
            sessionStorage.clear();
          }
          if (this.e1 > 5 && this.srcref && value > 5) {
            this.getStatment();
          }
        } else {
          this.snackAlert(0, raw ? raw : "Server is not reachable, please try again later. \n  For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com");
          this.cardloader = false;
          this.e1 = 0;
          this.elstage = false;
        }
      } else if (value == 402) {
        let data = JSON.stringify({
          cam_code: 402,
        });

        let config = {
          method: "post",
          url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        let axiosThis = this;
        axios
          .request(config)
          .then((response) => {
            if (response.data) {
              axiosThis.$router.push("/segment");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (value.length > 0) {
        let p = localStorage.getItem("cGFnZWlz");
        let s =
          value == "complete" && p == "submit"
            ? value
            : value == "submit" && p == "selfie"
            ? value
            : value == "selfie" && p == "nominee"
            ? value
            : value == "nominee" && p == "signature"
            ? value
            : value == "signature" && p == "declartaion"
            ? value
            : value == "declartaion" && p == "segment"
            ? value
            : value == "segment" && (p == "bank" || p == "bankproof")
            ? value
            : value == "bankproof" && p == "bank"
            ? value
            : value == "bank" && p == "personal"
            ? value
            : value == "personal" && (p == "verification" || p == "panproof")
            ? value
            : value == "panproof" && p == "verification"
            ? value
            : value == "verification" && (p == "onboard" || p == "pan")
            ? value
            : value == "pan" && (p == "onboard" || p == "email")
            ? value
            : value == "onboard" && p == "email"
            ? value
            : value == "email"
            ? value
            : null;
        if (s) {
          let data = JSON.stringify({
            stage: this.e1 != 7 ? s : "complete",
          });

          let config = {
            method: "post",
            url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          let axiosThis = this;
          axios
            .request(config)
            .then((response) => {
              localStorage.setItem("cGFnZWlz", axiosThis.e1 != 7 ? s : "complete");
              if (response.data.data_type.stage) {
                axiosThis.$router.push({name: s});
              }
            })
            .catch((error) => {
              console.log(error);
              axiosThis.cardloader = false;
              alert("Server is not reachable, please try again later. \n  For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com");
            });
        } else if (
          value == "email" ||
          value == "onboard" ||
          value == "pan" ||
          value == "verification" ||
          value == "panproof" ||
          value == "personal" ||
          value == "bank" ||
          value == "bankproof" ||
          value == "segment" ||
          value == "declartaion" ||
          value == "signature" ||
          value == "nominee" ||
          value == "selfie" ||
          value == "submit" ||
          value == "complete"
        ) {
          this.$router.push({name: value});
        }
      } else {
        this.e1 = 0;
        this.elstage = false;
        this.liveon = 0;
      }
    },
    stageUpdate(value) {
      let data = {
        reason: "",
        log: "",
      };
      data[value] = "";
      let dataa = JSON.stringify(data);
      let config = {
        method: "post",
        url: api.api_url + "/stage_update?mobileNo=" + atob(localStorage.getItem("hash")),
        headers: {
          "Content-Type": "application/json",
        },
        data: dataa,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (Object.keys(response.data).length > 1) {
            axiosThis.$router.push("/submit");
          } else {
            setTimeout(() => {
              axiosThis.stageUpdate(value);
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    snackAlert(color, msg) {
      this.snackbar = true;
      this.snackcolor = color == 0 ? "error" : color == 1 ? "success" : "warning";
      this.snacktxt = msg;
    },
    // mobile
    // email
    // onboard
    // pan
    // panproof
    // verification
    // personal
    // bank
    // bankproof
    // segment
    // declartaion
    // signature
    // nominee
    // selfie
    // submit
    // complete
  },
};
</script>

<style>
@import "../assets/style.css";
</style>
